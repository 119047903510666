<script setup>

</script>

<template>
  <div class="sperma-form-wrapper">
    <form class="sperma-form" method="post" action="#">
      <div>
        <label for="login">Логин:</label>
        <input type="text" name="login" id="login">
      </div>
      <div>
        <label for="password">Пароль:</label>
        <input type="password" name="password" id="password">
      </div>
      <input type="submit" value="Зарегистрироваться" id="submit">
    </form>
  </div>
</template>

<style scoped>
  .sperma-form-wrapper {
    z-index: 1;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sperma-form {
    grid-template-columns: 1fr;
    place-items: center;
    padding: 10px;
    display: grid;
    background: #222424;
    height: 500px;
    width: 600px;
    position: relative;
    border-radius: 10px;
  }

  .sperma-form > div > label {
    margin-bottom: 10px;
    color: #fff;
    font-size: 35px;
  }

  input:hover + label {
    background: linear-gradient(to right, blue, deeppink);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }

  .sperma-form > div > input:not([type="submit"]) {
    font-family: Raleway, sans-serif;
    border: none;
    border-bottom: 2px solid #fff;
    background: #222424;
    color: #fff;
    font-size: 35px;
    padding: 10px;
    height: 40px;
    width: 400px;
  }

  .sperma-form > div> input:not([type="submit"]):focus, .sperma-form > div> input:not([type="submit"]):hover {
    outline: none;
    border-bottom: 2px solid #fff;
    border-image: linear-gradient(to right, blue, deeppink) 1;
  }

  #submit {
    background: white;
    border-radius: 50px;
    background-clip: text;
    color: transparent;
    font-size: 35px;
    display: block;
    padding: 15px;
    border-color: transparent;
  }

  #submit:hover {
    background: linear-gradient(to right, blue, deeppink);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    border-image: conic-gradient(from var(--angle), blue, deeppink, blue) 1;
    animation: 3s spin infinite linear;
    cursor: pointer;
  }

  .sperma-form > div {
    display: grid;
  }

  .sperma-form-wrapper::after, .sperma-form-wrapper::before, #submit::after, #submit::before {
    content: "";
    width: 100%;
    height: 100%;
    background-image: conic-gradient(from var(--angle), blue, deeppink, blue);
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: -1;
    padding: 2px;
    border-radius: 10px;
    animation: 3s spin infinite linear;
  }

  #submit::after, #submit::before {
    z-index: 1;
  }

  .sperma-form-wrapper::before, #submit::before {
    filter: blur(1.5rem);
  }

  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes spin {
    from {
      --angle: 0deg;
    }
    to {
      --angle: 360deg;
    }
  }

  @font-face {
    font-family: Raleway;
    src: url("../../assets/fonts/Raleway-VariableFont_wght.ttf");
  }
</style>