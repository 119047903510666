<template>
  <RegisterForm/>
</template>

<script>
import RegisterForm from "@/components/RegisterForm/RegisterForm.vue";

export default {
  name: 'App',
  components: {
    RegisterForm,
  }
}
</script>

<style>
#app {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0d0d0d;
  font-family: Raleway, sans-serif;
  display: grid;
  place-items: center;
  margin: 0;
  width: 100vw;
  height: 100vh;
}
</style>
